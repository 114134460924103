import { errorText } from "@/api/errors";

export function isEmptyObj(obj) {
  return !!Object.keys(obj).length;
}

export async function getBase64(file) {
  let reader = new FileReader();
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    // eslint-disable-next-line no-unused-vars
    reader.onload = arg => {
      resolve(reader.result);
    };
  });
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function isValidSize(file) {
  if (file.size > 2 * 1024 * 1024) {
    return false;
  }
  return true;
}

export function imageCompress(file, obj) {
  return new Promise((resolve, reject) => {
    let { size, maxWidthOrHeight, fileType, qualityArgument } = obj;
    if (file && file.size) {
      //不需要压缩
      if (size && file.size <= size) {
        getBase64(file).then(base64 => {
          resolve({ file, base64 });
        });
        return;
      }
    } else {
      reject({
        msg: errorText["FILE_BROKEN"],
        code: "FILE_BROKEN"
      });
      return;
    }
    if (!/(jpg|jpeg|png)$/.test(file.type)) {
      reject({
        msg: errorText["INTERVAL_IMAGE_TYPE"],
        code: "INTERVAL_IMAGE_TYPE"
      });
      return;
    }
    fileType = fileType || file.type;
    switch (fileType) {
      case "jpg":
      case "jpeg":
      case "image/jpeg":
        fileType = "image/jpeg";
        break;
      case "png":
      case "image/png":
        fileType = "image/png";
        break;
      default:
        reject({
          msg: errorText["INTERVAL_IMAGE_TYPE"],
          code: "INTERVAL_IMAGE_TYPE"
        });
        return;
    }
    //canvas检测。canvas用来压缩图片
    let canvas = document.createElement("canvas");
    if (!canvas || !canvas.getContext) {
      reject({
        msg: errorText["NONSUPPORT_BROWSER"],
        code: "NONSUPPORT_BROWSER"
      });
      return;
    }
    let context = canvas.getContext("2d");

    //FileReader检测。FileReader用来转base64
    if (!window.FileReader) {
      reject({
        msg: errorText["NONSUPPORT_BROWSER"],
        code: "NONSUPPORT_BROWSER"
      });
      return;
    }
    let reader = new FileReader(),
      img = new Image();
    reader.readAsDataURL(file);

    reader.onload = function(e) {
      // e.target.result就是图片base64
      img.src = e.target.result;
    };
    img.onload = () => {
      let originWidth = img.width,
        originHeight = img.height,
        width = originWidth,
        height = originWidth;
      maxWidthOrHeight = maxWidthOrHeight || 2048;
      let ratio = 0.9;
      if (qualityArgument && qualityArgument < 1 && qualityArgument > 0) {
        ratio = qualityArgument;
      }
      if (originHeight > maxWidthOrHeight) {
        height = maxWidthOrHeight;
        width = (originWidth / originHeight) * maxWidthOrHeight;
      } else if (originWidth > maxWidthOrHeight) {
        width = maxWidthOrHeight;
        height = (originHeight * maxWidthOrHeight) / originWidth;
      } else {
        width = originWidth * ratio;
        height = originHeight * ratio;
      }

      canvas.width = width;
      canvas.height = height;
      context.drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        function(blob) {
          if (size) {
            if (blob.size <= size) {
              getBase64(blob).then(base64 => {
                resolve({ file: blob, base64 });
              });
            } else {
              resolve(imageCompress(blob, obj));
            }
          } else {
            getBase64(blob).then(base64 => {
              resolve({ file: blob, base64 });
            });
          }
        },
        fileType,
        qualityArgument
      );
    };
  });
}

export function clearTimeoutTimers(timers) {
  for (let timer of timers) {
    clearTimeout(timer);
  }
}

export function getImgDocObjectAndSize(src) {
  return new Promise(resolve => {
    let testImage = new Image();
    let result = {
      width: 0,
      height: 0
    };
    testImage.onload = () => {
      result = {
        width: testImage.naturalWidth,
        height: testImage.naturalHeight
      };
      resolve({ size: result, img: testImage, src });
    };
    testImage.src = src;
  });
}

// ArrayBuffer对象 Unicode码转字符串
function getStringFromCharCode(dataView, start, length) {
  let str = "";
  let i;
  for (i = start, length += start; i < length; i++) {
    str += String.fromCharCode(dataView.getUint8(i));
  }
  return str;
}

// base64转ArrayBuffer对象
function base64ToArrayBuffer(base64) {
  base64 = base64.replace(/^data:([^;]+);base64,/gim, "");
  let binary = atob(base64);
  let len = binary.length;
  let buffer = new ArrayBuffer(len);
  let view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return buffer;
}

// 获取jpg图片的exif的角度（在ios体现最明显）
export function getOrientation(base64) {
  if (!isBase64(base64)) {
    return;
  }
  let arrayBuffer = base64ToArrayBuffer(base64);
  let dataView = new DataView(arrayBuffer);
  let length = dataView.byteLength;
  let orientation;
  let exifIDCode;
  let tiffOffset;
  let firstIFDOffset;
  let littleEndian;
  let endianness;
  let app1Start;
  let ifdStart;
  let offset;
  let i;
  // Only handle JPEG image (start by 0xFFD8)
  if (dataView.getUint8(0) === 0xff && dataView.getUint8(1) === 0xd8) {
    offset = 2;
    while (offset < length) {
      if (
        dataView.getUint8(offset) === 0xff &&
        dataView.getUint8(offset + 1) === 0xe1
      ) {
        app1Start = offset;
        break;
      }
      offset++;
    }
  }
  if (app1Start) {
    exifIDCode = app1Start + 4;
    tiffOffset = app1Start + 10;
    if (getStringFromCharCode(dataView, exifIDCode, 4) === "Exif") {
      endianness = dataView.getUint16(tiffOffset);
      littleEndian = endianness === 0x4949;

      if (littleEndian || endianness === 0x4d4d /* bigEndian */) {
        if (dataView.getUint16(tiffOffset + 2, littleEndian) === 0x002a) {
          firstIFDOffset = dataView.getUint32(tiffOffset + 4, littleEndian);

          if (firstIFDOffset >= 0x00000008) {
            ifdStart = tiffOffset + firstIFDOffset;
          }
        }
      }
    }
  }
  if (ifdStart) {
    length = dataView.getUint16(ifdStart, littleEndian);

    for (i = 0; i < length; i++) {
      offset = ifdStart + i * 12 + 2;
      if (
        dataView.getUint16(offset, littleEndian) === 0x0112 /* Orientation */
      ) {
        // 8 is the offset of the current tag's value
        offset += 8;

        // Get the original orientation value
        orientation = dataView.getUint16(offset, littleEndian);

        // Override the orientation with its default value for Safari (#120)
        // if (IS_SAFARI_OR_UIWEBVIEW) {
        //     dataView.setUint16(offset, 1, littleEndian)
        // }
        break;
      }
    }
  }
  return orientation;
}

export async function rotateImage(src) {
  // console.log(src, 11111);
  let type = src.type;
  let base64 = await getBase64(src);
  let { size, img } = await getImgDocObjectAndSize(base64);
  let orientation = getOrientation(base64);
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  let { height, width } = size;
  let w, h;
  if (height > width) {
    h = Math.min(4096, height);
    w = Math.min((4096 * width) / height, width);
  } else {
    w = Math.min(4096, width);
    h = Math.min((4096 * height) / width, height);
  }
  // console.log(width, height, w, h);
  width = w;
  height = h;
  // 设置画布宽高
  canvas.width = width;
  canvas.height = height;
  // console.log(orientation, "orientation");
  const u = navigator.userAgent;
  if (u.match(/iphone/i)) {
    const verinfo = navigator.userAgent.match(/os [\d._]*/gi);
    const version = (verinfo + "")
      .replace(/[^0-9|_.]/gi, "")
      .replace(/_/gi, "."); //获取具体的系统版本号
    const [major, minor] = version.split(".");
    if (major && Number(major) >= 13 && Number(minor) >= 4) {
      if (orientation === 6 || orientation === 3 || orientation === 8) {
        orientation = 1;
      }
    }
  }
  // console.log(orientation, "2orientation");

  if (orientation >= 5) {
    canvas.width = height;
    canvas.height = width;
  }

  // 更具 orientation 旋转画布
  switch (orientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, height, width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width);
      break;
    default:
      ctx.transform(1, 0, 0, 1, 0, 0);
  }
  // 画图
  ctx.drawImage(img, 0, 0, width, height);
  const _base64 = canvas.toDataURL(type);
  // 把图片转换成 dataURL
  return {
    base64: _base64,
    size: { width: canvas.width, height: canvas.height },
    img,
    rotateFile: dataURLtoFile(_base64, src.name),
    file: src
  };
}

export function isBase64(str) {
  if (!str) return false;
  return /^data:image\/.*;base64,/.test(str);
}

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function mergeDeep(target, source) {
  let output = Object.assign({}, target);
  for (let key in source) {
    if (typeof source[key] === "object" && source[key] !== null) {
      output[key] = mergeDeep(target[key], source[key]);
    } else if (source[key] !== null) {
      // eslint-disable-next-line no-prototype-builtins
      if (!target.hasOwnProperty(key) || target[key] === null) {
        output[key] = source[key];
      }
    }
  }
  return output;
}

export function judgeClient() {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //判断是否是 android终端
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
  if (isAndroid) {
    return "Android";
  } else if (isIOS) {
    return "IOS";
  } else {
    return "PC";
  }
}
