export async function getNaturalSize(src, returnImageObject) {
  return new Promise(resolve => {
    let image = new Image();
    let result = {
      width: 0,
      height: 0
    };

    image.onload = () => {
      result = {
        width: image.naturalWidth,
        height: image.naturalHeight
      };
      resolve(returnImageObject ? { result, image } : result);
    };
    image.src = src;
  });
}

export function getRatio(rect, container) {
  return Math.min(container.width / rect.width, container.height / rect.height);
}

/*
 * 求矩形框中心点位置center
 */
export function getCenterPoint(rect) {
  let top = rect.y || 0;
  let left = rect.x || 0;
  return {
    x: left + rect.width / 2,
    y: top + rect.height / 2
  };
}

/*
 * 给定一个点，一个角度alfa，一个旋转基准点，输出旋转后的点位
 */
export function getRotatedPoint(point, alfa, basePoint) {
  alfa = (alfa * Math.PI) / 180;
  let l = Math.sqrt(
    Math.pow(point.x - basePoint.x, 2) + Math.pow(point.y - basePoint.y, 2)
  );
  if (l === 0) {
    return Object.assign({}, basePoint);
  }
  let cosBeta = (point.x - basePoint.x) / l;
  let sinBeta = (point.y - basePoint.y) / l;
  return {
    x: basePoint.x + l * (Math.cos(alfa) * cosBeta - Math.sin(alfa) * sinBeta),
    y: basePoint.y + l * (Math.cos(alfa) * sinBeta + Math.sin(alfa) * cosBeta)
  };
}

/*
 * 给定一个固定点，以该点为中心缩放图案，计算图案上任意点的新坐标
 */
export function scale(point, ratio, basePoint) {
  return {
    x: basePoint.x + (point.x - basePoint.x) * ratio,
    y: basePoint.y + (point.y - basePoint.y) * ratio
  };
}

export function random(interval, type, tofixed) {
  if (type === "int") {
    return Number(
      Math.floor(Math.random() * (interval[1] - interval[0] + 1)) + interval[0]
    );
  }
  const fixed = tofixed && tofixed >= 0 && tofixed <= 20 ? tofixed : 2;
  return Number(
    (Math.random() * (interval[0] - interval[1]) + interval[1]).toFixed(fixed)
  );
}

export function isRectInPic(rect, pic) {
  const { top, left, bottom, right } = rect;
  for (let p of [top, left, bottom, right]) {
    if (p.x < 0 || p.x > pic.width) {
      return false;
    }
    if (p.y < 0 || p.y > pic.height) {
      return false;
    }
  }
  return true;
}

export function getFontSize() {
  return Number(document.documentElement.style.fontSize.replace("px", ""));
}

// 给定一个点，一个缩放基准点，一个旋转基准点，角度alfa，缩放倍数，返回经过旋转缩放后的点位
export function calculateDevicePoint(
  point,
  scaleBasePoint,
  rotateBasePoint,
  alfa,
  ratio,
  offset,
  conso
) {
  let rotatePoint = getRotatedPoint(point, alfa, rotateBasePoint);
  let scalepoint = scale(rotatePoint, ratio, scaleBasePoint);
  let offsetPoint = {
    x: scalepoint.x + offset.left,
    y: scalepoint.y + offset.top
  };
  if (conso) {
    console.log(
      "point:",
      point,
      "rotatePoint:",
      rotatePoint,
      "scalepoint:",
      scalepoint,
      "offsetPoint:",
      offsetPoint
    );
  }
  return offsetPoint;
  // let offsetPoint = {
  //     x: rotatePoint.x + offset.left,
  //     y: rotatePoint.y + offset.top
  // }
  //return scale(offsetPoint, ratio, scaleBasePoint)
}

export function getFaceRect(denselandmark) {
  let mainFacePoints = {
    top: denselandmark.face.face_hairline_72,
    right: denselandmark.face.face_hairline_0,
    left: denselandmark.face.face_hairline_144,
    bottom: denselandmark.face.face_contour_right_0
  };
  let faceRect = {
    width: Math.abs(mainFacePoints.left.x - mainFacePoints.right.x),
    height: Math.abs(mainFacePoints.top.y - mainFacePoints.bottom.y),
    x: mainFacePoints.left.x,
    y: mainFacePoints.top.y
  };
  return {
    faceRect,
    mainFacePoints
  };
}

// 给定两个点，一个距离，输出方向矢量上延长该距离的坐标
export function extendPoints(start, end, distance) {
  let p1;
  const l = Math.sqrt(
    Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2)
  );
  if (l === 0) {
    return null;
  }
  p1 = {
    x: (distance / l) * (end.x - start.x) + end.x,
    y: (distance / l) * (end.y - start.y) + end.y
  };
  return p1;
}

export function imageSmoothingEnabled(ctx, open) {
  // 关闭抗锯齿
  // ctx.mozImageSmoothingEnabled = open
  // ctx.webkitImageSmoothingEnabled = open
  // ctx.msImageSmoothingEnabled = open
  ctx.imageSmoothingEnabled = open;
}

export function getAllowFaceRect(allowFaceRect) {
  const fontsize = document.documentElement.clientWidth / 3.6;
  return {
    ...allowFaceRect,
    top:
      (1 / 4) *
      Math.abs(
        Math.max(
          document.documentElement.clientHeight,
          document.body.clientHeight
        ) /
          fontsize -
          allowFaceRect.height
      ),
    left:
      Math.max(
        document.documentElement.offsetWidth,
        document.body.offsetWidth
      ) /
        (fontsize * 2) -
      allowFaceRect.width / 2
  };
}
